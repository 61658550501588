import React from "react"

import Layout from "../templates/Page"
import Footer from "../components/ContentBuilder/Footer"
import SwiperList from "../components/ContentBuilder/SwiperList"
import Header from "../components/ContentBuilder/Header"
import Subscribe from "../components/Footer/subscribe"

const LandingDetailPage = () => {
  return (
    <Layout hideHeader>
      <Header isLogin />

      <div className="container">
        <div className="d-none d-sm-flex justify-content-between align-items-center wrap-section mt-30 mb-30">
          <div className="product-name-title">Gregory Hills, NSW</div>
          <div className="d-flex align-items-center">
            <div className="w-100 mr-4">Add to board</div>
            <img src="/img/hearth.svg" />
          </div>
        </div>

        <div className="row wrap-section no-margin">
          <div className="col-sm-8 p-0 mt-5 mt-sm-0">
            <div className="landing-detail-image-wrap"></div>
          </div>
          <div className="col-sm-4 p-0">
            <div className="d-flex d-sm-none justify-content-between align-items-center mt-30 border-bottom-cell">
              <div className="product-name-title">Gregory Hills, NSW</div>
              <div className="d-flex align-items-center">
                <div className="w-100 mr-4">Add to board</div>
                <img src="/img/hearth.svg" />
              </div>
            </div>

            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <div className="information-text">INFORMATION</div>
              <div>
                <img src="/img/openLink.svg" />
              </div>
            </div>
            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <div className="title-cell">Price from</div>
              <div className="price-cell">$268,900.00</div>
            </div>
            <div className="border-bottom-cell">
              <div className="title-cell">Address</div>
              <div className="right-cell">
                Lot 28 - Melon Circuit, Gregory Hills. 2567
              </div>
            </div>
            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <div className="title-cell">Approx Land area</div>
              <div className="right-cell">598m</div>
            </div>
            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <div className="title-cell">Land width</div>
              <div className="right-cell">598m</div>
            </div>
            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <div className="title-cell">Land length</div>
              <div className="right-cell">598m</div>
            </div>
            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <div className="title-cell">Council</div>
              <div className="right-cell">598m</div>
            </div>
            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <div className="title-cell">Est Registration date</div>
              <div className="right-cell">598m</div>
            </div>
            <div className="border-bottom-cell d-flex justify-content-between align-items-center">
              <div className="title-cell">Documents</div>
              <div className="right-cell">598m</div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-30 mb-30">
              <button className="btn add-to-board-button">
                <img src="/img/hearth.svg" style={{ height: 30, width: 30 }} />
                <span className="ml-2">Add to board</span>
              </button>
              <button className="btn  enquire-button">Enquire Now</button>
            </div>
          </div>
        </div>

        <SwiperList title="Other land this home works with" navClass="land" />
        <SwiperList title="Other homes that fit this land" navClass="home" />
        <SwiperList title="Available facade options" navClass="facade" />
        <SwiperList title="Works with these styles" navClass="works" />
        <SwiperList title="Related items" navClass="related" />
        
      </div>
      <Subscribe></Subscribe>
    </Layout>
  )
}

export default LandingDetailPage
